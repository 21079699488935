/**
 * 作用：按需引入 echarts 减少打包体积，在 NormalEchats 组件中使用
 * 说明：根据 官网实例，对比出没有的配置项 进行加入
 *
 */
// 1.引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";

// 2.引入柱状图图表，图表后缀都为 Chart
import { BarChart, GraphChart,LineChart,EffectScatterChart } from "echarts/charts";
// 3.引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  
} from "echarts/components";
import { GeoComponent } from 'echarts/components';
echarts.use([GeoComponent]);
import { ScatterChart } from 'echarts/charts';
echarts.use([ScatterChart]);
import { LinesChart } from 'echarts/charts';
echarts.use([LinesChart]);
// 4.引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
//3D地图
import { MapChart } from 'echarts/charts';
echarts.use([MapChart]);
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  GraphChart,
  CanvasRenderer,
  LegendComponent,
  LineChart,
  EffectScatterChart
]);

export default echarts;
