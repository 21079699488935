<template>
  <div :ref="refName" class="chartBox" />
</template>

<script>
// 引入 配置完成的 echarts
import echarts from "@/utils/echarts";
// 引入默认配置项，

export default {
  name: "BaseEchars",
  props: {
    refName: {
      // 图例绑定的dom  一个页面中存在多个图例时不要重复
      type: String,
      required: true,
    },
    option: {
      // echarts 配置项  直接从官网复制粘贴即可
      required: true,
    },
  },
  data() {
    return {
      chart: null, // echarts 实例
    };
  },
  watch: {
    // 监听 echarts 变化 自动更新
    option: {
      deep: true,
      handler() {
        console.log("数据变化了，视图更新了");
        this.chart.setOption(this.chartOption);
        this.$emit("getChart", this.chart);
      },
    },
  },
  computed: {
    defaultOption() {
      //默认配置项   这里的默认配置项 只能兼容 option 第一层级的属性
      return {
        // tooltip: {
        //   trigger: "axis",
        //   axisPointer: {
        //     type: "cross",
        //     label: {
        //       backgroundColor: "#6a7985",
        //     },
        //   },
        // },
      };
    },
    chartOption() {
      // 最终配置项
      return { ...this.defaultOption, ...this.option };
    },
  },
  methods: {
    // 初始化实例
    initChart() {
      this.chart = echarts.init(this.$refs[this.refName]);
      // 当前配置项 优先级大于 默认配置项
      // console.log("当前视图的配置项", this.chartOption);
      this.chart.setOption(this.chartOption);
      this.$emit("getChart", this.chart);
    },
    // 自适应
    resizeHandler() {
      this.chart.resize();
    },
  },
  mounted() {
    // 如果传入的 option 为空则不进行初始化
    if (!this.option || this.option === "{}") {
      return;
    }
    this.initChart();
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>

<style scoped>
.chartBox {
  width: 100%;
  height: 100%;
}
</style>
