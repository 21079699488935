import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 1. 全局注册 elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// 2. 全局注册 封装的 echarts 根组件
import 'animate.css';

import BaseEchart from "@/components/BaseEchart";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import elementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//引入样式
import bmap from 'vue-baidu-map'
Vue.component("BaseEchart", BaseEchart);
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)
Vue.use(elementUI);

// 需要在Vue中使用组件
Vue.config.productionTip = false;
Vue.use(bmap, {
  ak: '8LTNiwg0yzV9IGRjuF2GAz7CmzwnCgYs'
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
